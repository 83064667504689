import React from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../../components/Layout"
import { Installment } from "../../components/inmyroom/Installment"
import { Form } from "../../components/inmyroom/Form"
import { Banner } from "../../components/inmyroom/Banner"
import { VideoBlock } from "../../components/VideoBlock"
import { PageData } from "../../interfaces/pageProps"
import { getPageData } from "../../helpers/getPageData"

export default function Inmyroom({ data }: PageProps<PageData>) {
  const { seoData, ligal, phones } = getPageData(data)
  return (
    <Layout
      headerProps={{
        PhoneProps: {
          phone: "8 800 100-77-72",
        },
      }}
      seoData={seoData}
      shortText={ligal.shortText}
      phones={phones}
    >
      <Banner />
      <Installment />
      <Form />
      <VideoBlock />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://go.halvacard.ru/inmyroom/") {
        url
        phones
        ligal {
          text
        }
      }
    }
    pageDataJson(pageName: { eq: "inmyroom" }) {
      seoData {
        title
        description
      }
    }
  }
`
