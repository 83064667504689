import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import { Img } from "./Img"
import { scrollToPersonalForm } from "../../../helpers/scrollToPersonalForm"
import * as styles from "./banner.module.scss"

export function Banner() {
  return (
    <section className={styles.section}>
      <Img alt="image" className={styles.img} />
      <Container className={styles.container}>
        <h1 className={styles.title}>
          Получи + 3 месяца
          <br />
          рассрочки!
        </h1>
        <p className={styles.subTitle}>
          Активируйте промокод и получите <br />
          дополнительную рассрочку <br className={styles.br1} />
          на покупку <br className={styles.br2} />в любом магазине-партнере!
        </p>
        <Button className={styles.btn} onClick={scrollToPersonalForm}>
          Получить промокод
        </Button>
      </Container>
    </section>
  )
}
