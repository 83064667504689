import React from "react"
import Container from "@ecom/ui/components/Container"

import * as styles from "./installment.module.scss"

export function Installment() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.wrap}>
          <h1 className={styles.title}>Получите + 3 месяца рассрочки!</h1>
          <p className={styles.description}>
            Активируйте промокод от INMYROOM и используйте в любом магазине-партнере!
          </p>
          <h1 className={styles.subTitle}>Как получить дополнительную рассрочку?</h1>
          <ol className={styles.list}>
            <li>Введите в форму ниже дату рождения и номер телефона</li>
            <li>Получите смс с промокодом</li>
            <li>Активируйте промокод в личном кабинете или в мобильном приложении</li>
          </ol>
        </div>
      </Container>
    </section>
  )
}
