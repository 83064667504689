import React from "react"

import { navigate } from "gatsby"

import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import DateField from "@ecom/ui/components/FormLanding/fields/DateField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import sendRequest from "@ecom/ui/utils/sendRequest"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"

import * as styles from "./form.module.scss"
import { handleClickForm } from "../../../helpers/WatcherDL/utils/handleClickForm"
import { DLGAFormEvent } from "../../../helpers/WatcherDL/DLEvents"

const fieldsType = {
  birthDate: "date",
  phone: "phone",
}

export function Form() {
  function onSubmit(values: any) {
    pushToDataLayer({
      event: "GAFormEvent",
      name: "Продолжить",
      placement: "appl_halva",
      eventAction: "passportForm",
      eventCategory: "sendForm",
    })
    const valuesToRequest = formatToRequest(values, fieldsType)
    sendRequest(`${process.env.GATSBY_APP_URL_API}/v2/app/promo`, {
      ...valuesToRequest,
    })
      .then((res: { data: any }) => {
        pushToDataLayer({ event: "applicationSend", result: "success" })
        DLGAFormEvent({
          eventCategory: "sendForm",
          eventAction: "personalForm",
          placement: "main",
        })
        res.data = valuesToRequest
        navigate("/inmyroom/sms", { state: res })
      })
      .catch(() => {
        pushToDataLayer({ event: "applicationSend", result: "error" })
        navigate("error")
      })
  }

  const onChangeFields = {
    phone: () => {
      handleClickForm()
    },

    birthDate: () => {
      handleClickForm()
    },
  }

  return (
    <section className="gradient-bottom" id="form">
      <Container className={styles.container}>
        <FormLanding onSubmit={onSubmit} onChangeFields={onChangeFields}>
          <h1 className={styles.head}>Форма заявки</h1>
          <DateField
            name="birthDate"
            placeholder="дд.мм.гггг"
            max={new Date()}
            validAgeMin={18}
            validAgeMax={85}
            label="Дата рождения"
          />
          <PhoneField name="phone" label="Телефон" />
          <div className={styles.btnOuter}>
            <SubmitButton className={styles.btn}>Получить промокод</SubmitButton>
          </div>
          <div className={styles.small}>
            <b>
              Если у вас ещё нет Халвы, то вы будете перенаправлены на оформление карты. После
              быстрого заполнения формы на ваш телефон придёт смс с промокодом.
            </b>
            <br />
            Кстати с Халвой вы можете покупать в беспроцентную рассрочку товары в 250 000
            магазинах-партнерах, брать заемные средства до 500 000 рублей, получать кешбэк до 10% и
            дополнительный доход с удобной онлайн-копилкой
          </div>
        </FormLanding>
      </Container>
    </section>
  )
}
