import React from "react"
import Container from "@ecom/ui/components/Container"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ReactPlayer from "react-player/lazy"
import { PlayButton } from "./PlayButton"

import * as styles from "./videoBlock.module.scss"

export function VideoBlock() {
  const isTablet = useMediaQuery("(min-width:600px)")
  const isDesk = useMediaQuery("(min-width:960px)")
  let width
  let height
  if (isDesk) {
    width = 600
    height = 338
  } else if (isTablet) {
    width = 440
    height = 248
  } else {
    width = 328
    height = 185
  }
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <ReactPlayer
          className={styles.video}
          playing
          loop
          controls
          width={width}
          height={height}
          playIcon={<PlayButton className={styles.playButton} />}
          light="/image.jpg"
          url="/video.mp4"
        />
        <div className={styles.textWrap}>
          <h2 className={styles.title}>
            Чтобы автоматически пересчитать срок рассрочки, активируйте промокод в приложении сразу
            после совершения покупки
          </h2>
          <div className={styles.brief}>
            Теперь у вас есть дополнительные 3 месяца рассрочки на одну покупку в любом
            магазине-партнере!
          </div>
        </div>
      </Container>
    </section>
  )
}
