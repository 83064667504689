import React from "react"

type PlayButtonProperty = {
  className?: string
}

export function PlayButton({ className }: PlayButtonProperty) {
  return (
    <svg className={className} viewBox="0 0 268 268" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="134" cy="134" r="134" fill="white" />
      <path
        d="M183.655 126.154C189.038 129.262 189.038 137.031 183.655 140.139L115.358 179.57C109.975 182.678 103.247 178.793 103.247 172.578L103.247 93.7152C103.247 87.4997 109.975 83.6149 115.358 86.7227L183.655 126.154Z"
        fill="black"
      />
    </svg>
  )
}
