/**
 * DataLayer - GAFormEvent event
 * @description Все действия на форме записываются в событие – GAFormEvent. Событие, которое происходит в процессе прохождения * пользователя по воронке от получения лида (номер телефона + фио) к успешной отправке многошаговых форм
 *
 * @description Отличие при заполнение success и error варианта, ориентир на типы TGAFormEventSuccess и TGAFormEventError
 *
 * @example Success - DLGAFormEvent({
 *               eventCategory: "formSuccess",
 *               eventAction: "deliveryForm",
 *               placement: "calculator",
 *               time: new Date().getTime(),
 *            })
 * @example Error - DLGAFormEvent({
 *              eventCategory: "server_error",
 *              eventAction: "error_apply",
 *             })
 * @default event : GAFormEvent
 * @default time : текущее время отправки в миллисекундах
 *
 * @param eventCategory : TDLFormEventCategory
 * @param eventAction : TDLFormEventAction
 * @param eventLabel: Страница на которой произошло событие
 * @param placement: Расположение в форме "main - основная форма" или "calculator - форма в калькуляторе"
 */

import { pushToDL } from "../utils/helper"
import type { TDLFormPlacement, TDLLandingsPlacementError, TDLPageURL } from "./type"

type TDLFormEventCategory =
  | "stepLoaded" // Шаг формы загружен
  | "stepSuccess" // Отправка валидного шага.
  | "formSuccess" // Многошаговая форма успешно отправлена
  | "sendForm" // Попытка отправки формы (нажатие на кнопку «Отправить») вне зависимости от валидации

type TDLFormEventAction =
  | "personalForm" // Первый шаг заявки
  | "longPersonalForm" // Валидно заполненное поле
  | "pickupForm" // Шаг самовывоза
  | "passportForm" // Шаг паспортных данных
  | "deliveryForm" // Шаг доставки
  | "employmentForm" // Шаг занятости
  | "passportNAddressForm" // Шаг паспорта и адреса
  | "workInfo" // Шаг работы
  | "contacts" // Шаг контакты
  | "estate" // Шаг имущество

type TGAFormEventSuccess = {
  eventCategory: TDLFormEventCategory
  eventAction: TDLFormEventAction
  placement: TDLFormPlacement
  eventLabel?: TDLPageURL
  time?: number
}

type TGAFormEventError = {
  eventCategory: "server_error" // При тех.ошибке
  eventAction: TDLLandingsPlacementError // Посадка на которой была ошибка пример error_apply | error_order, etc.
  eventLabel?: TDLPageURL
}

export const DLGAFormEvent = (formStatus: TGAFormEventSuccess | TGAFormEventError) => {
  const defaultLocation = formStatus.eventLabel ?? globalThis?.location?.href
  if ("placement" in formStatus) {
    const defaultTime = formStatus.time ?? new Date().getTime()

    pushToDL({
      event: "GAFormEvent",
      eventCategory: formStatus.eventCategory,
      eventAction: formStatus.eventAction,
      placement: formStatus.placement,
      eventLabel: defaultLocation,
      time: defaultTime,
    })
  } else {
    pushToDL({
      event: "GAFormEvent",
      eventAction: formStatus.eventAction,
      eventLabel: defaultLocation,
    })
  }
}
